import styles from './ComponentInsightListing.module.scss';

import { InsightLatest, InsightLatestCardListing } from '@/types';
import { ComponentCtaButton, Card } from '@/ui';

export interface InsightLatestCardListingProps extends InsightLatestCardListing {
  pageData?: {
    latestInsightsCards?: {
      largeCard: InsightLatest;
      smallCards: Array<InsightLatest>;
    };
  };
}

const ComponentInsightListing = ({
  insightsHeading,
  seeAllText,
  seeAllLink,
  showDateInLargeCard,
  largeCard,
  smallCards,
  ctaText,
  dynamicLatestInsights,
  ...props
}: InsightLatestCardListingProps) => {

  const { latestInsightsCards } = props?.pageData || {};
  const hasHeading = !!insightsHeading.length;
  const cardHeadingVariant = hasHeading ? 'h3' : 'h2';

  const hasButton = !!seeAllText && !!seeAllLink;
  const classNames = [styles.card1, styles.card2, styles.card3, styles.card4];
  
  if (dynamicLatestInsights) {
    if (!latestInsightsCards) {
      return null;
    }
    largeCard = latestInsightsCards.largeCard;
    smallCards = latestInsightsCards.smallCards;
  }

  return (
    <section className={styles.insightCardListing}>
      <div className={styles.insightCardListing__inner}>
        {(hasHeading || hasButton) && (
          <div className={styles.insightCardListing__top}>
            {hasHeading && (
              <h2 className={styles.insightCardListing__heading}>
                {insightsHeading}
              </h2>
            )}

            {hasButton && (
              <div className={styles.insightCardListing__button}>
                <span className={styles.insightCardListing__cta}>
                  <ComponentCtaButton
                    ctaType='link'
                    ctaText={seeAllText}
                    ctaStyle='underline'
                    ctaIcon='arrow'
                    ctaIconPosition='right'
                    ctaUrl={seeAllLink}
                  />
                </span>
              </div>
            )}
          </div>
        )}
        <div className={styles.insightCardListing__grid}>
          <div className={styles.largecard}>
            <Card
              key={largeCard.sys.id}
              heading={largeCard.pageHeading}
              image={largeCard.previewMedia}
              link={largeCard.slug}
              tags={largeCard.pageTag}
              cardHeadingVariant={cardHeadingVariant}
              ctaText={ctaText}
              insightsLarge={true}
              insightTypeReference={largeCard.insightTypeReference}
              publishDate={showDateInLargeCard ? largeCard.publishDate : null}
            ></Card>
          </div>
          {smallCards.map((card: InsightLatest, index: number) => {
            const className = classNames[index] || '';
            return (
              <div key={card.sys.id} className={className}>
                <Card
                  heading={card.pageHeading}
                  link={card.slug}
                  tags={card.pageTag}
                  cardHeadingVariant={cardHeadingVariant}
                  ctaText={ctaText}
                  insightsSmall={true}
                  insightTypeReference={card.insightTypeReference}
                  publishDate={card.publishDate}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ComponentInsightListing;
